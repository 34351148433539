import React from 'react';
import {user} from 'spekit-datalayer';
import {ControlledSelect} from 'spekit-ui';
import {useFormContext} from 'react-hook-form';

interface IExpertPickerProps {
  tags: string[];
}

export const ExpertPicker = ({tags}: IExpertPickerProps) => {
  const {setValue, watch} = useFormContext();
  const expertValue = watch('expert');

  const getExperts = async (searchText: string) => {
    try {
      let {results} = await user.getExperts(searchText, tags);
      if (expertValue?.value) {
        const expertExists = results.some((expert) => expert.value === expertValue.value);
        if (!expertExists) {
          setValue('expert', null);
        }
      }
      return results;
    } catch (err) {
      return [];
    }
  };

  return (
    <ControlledSelect
      isClearable
      testId='expert-picker'
      menuTestId='expert-picker-menu'
      key={JSON.stringify(tags)}
      name='expert'
      id='expert'
      label='Expert'
      labelTooltip='Expert will be notified they’ve been assigned to this Spek'
      placeholder='Select Expert'
      isAsync
      loadOptions={async (searchText: string) => getExperts(searchText)}
      cacheOptions
      defaultOptions
    />
  );
};
